import { useEffect } from 'react'

export default ({ name, start_url }) => {
    useEffect(() => {
        if (typeof window === 'undefined') return
        const manifest = {
            name: `${name} | Verifeco`,
            short_name: `${name} | Verifeco`,
            start_url,
            background_color: 'black',
            theme_color: 'black',
            display: 'standalone',
            icons: [
                {
                    src:
                        'icons/icon-48x48.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '48x48',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-72x72.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '72x72',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-96x96.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '96x96',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-144x144.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '144x144',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-192x192.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '192x192',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-256x256.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '256x256',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-384x384.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '384x384',
                    type: 'image/png',
                },
                {
                    src:
                        'icons/icon-512x512.png?v=a0315ae692e887be4daf75462facf03a',
                    sizes: '512x512',
                    type: 'image/png',
                },
            ],
        }

        const stringManifest = JSON.stringify(manifest)

        const blob = new Blob([stringManifest], { type: 'application/json' })

        const manifestURL = URL.createObjectURL(blob)
        document
            .querySelector('[rel="manifest"]')
            .setAttribute('href', manifestURL)
    }, [start_url, name])

    return null
}
