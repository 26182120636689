import React, { useState, useEffect } from 'react'

import { FaWhatsapp, FaSms, FaEnvelope } from 'react-icons/fa'

import styled, { css } from 'styled-components'

import Input from './input'

import Button from './button'

import { IoIosCloseCircleOutline } from 'react-icons/io'

import Portal from './portal'

import { GOLDEN } from './styles'

import { API_URL } from './input'

const SHARE_TEXT = `Here is my verified business cad MY_CARD_URL
 Get yours today CREATE_BUSINESS_CARD_URL`

const MAIL_SUBJECT = 'Verifeco - Business Card'

const iconStyles = css`
    color: #5f778a;
    padding: 0.25rem;
    width: 30px;
    height: 30px;
    background-color: white;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.5rem 0 0;
    @media print {
        display: none;
    }
`

const OnlyAddToContactsContainer = styled.div`
    display: inline-block;
    padding: 0.5rem 0 0;
`

const Whatsapp = styled(FaWhatsapp)`
    ${iconStyles}
`

const Sms = styled(FaSms)`
    ${iconStyles}
`

const Envelope = styled(FaEnvelope)`
    ${iconStyles}
`

const ShareLink = styled.a.attrs(() => ({ target: '_blank' }))`
    text-decoration: none;
    display: block;
    margin-right: 0.5rem;
    &:last-child {
        margin-right: 0;
    }
`
// eslint-disable-next-line
const Contact = styled.a`
    color: #5f778a;
    padding: 0 ${GOLDEN * 0.5}rem;
    height: 30px;
    background-color: white;
    font-size: 0.7rem;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    user-select: ${props => (props.allowSelection ? 'all' : 'none')};
    background-color: white;
    border: 1px solid currentColor;
    border-radius: 3px;
    margin-right: 0.5rem;

    letter-spacing: 1px;
`

// function iOS() {
//     if (typeof window === 'undefined') return false
//
//     const browser = Bowser.getParser(window.navigator.userAgent)
//
//     return browser.satisfies({
//         ios: {
//             safari: '>9',
//         },
//     })
// }

function createBusinessCardUrl() {
    return 'https://www.verifeco.com/create-business-card'
}

function makeShareMessage(type) {
    let formattedMessage = ''

    if (typeof window !== 'undefined')
        formattedMessage = SHARE_TEXT.replace(
            'MY_CARD_URL',
            window.location.href
        )

    formattedMessage = formattedMessage.replace(
        'CREATE_BUSINESS_CARD_URL',
        createBusinessCardUrl()
    )

    if (type === 'sms') {
        formattedMessage = encodeURI(formattedMessage)
    }

    if (type === 'whatsapp') {
        formattedMessage = `${encodeURI(formattedMessage)}`
    }

    return formattedMessage
}

function makeShareLink(type, recepient) {
    switch (type) {
        case 'whatsapp':
            return `https://api.whatsapp.com/send?phone=${recepient.replace(
                /[a-zA-Z+-\s]/g,
                ''
            )}&text=${makeShareMessage(type)}`

        case 'sms':
            return `sms:${recepient}?&body=${makeShareMessage(type)}`
        case 'email':
            return `mailto:${recepient}?body=${makeShareMessage(
                type
            )}&subject=${MAIL_SUBJECT}`

        default:
            return ''
    }
}

export default ({ demo, card = {}, onlyAddToContacts }) => {
    const [isOpen, setOpen] = useState(false)

    const [type] = useState('')

    const [inputType] = useState('')

    const [inputPlaceHolder] = useState('')

    const [recepient, setRecepient] = useState(null)

    const [shareLink, setShareLink] = useState(null)

    const [shareTitle] = useState('')

    useEffect(() => {
        setShareLink(makeShareLink(type, recepient))
    }, [type, recepient])

    if (onlyAddToContacts) {
        return (
            <OnlyAddToContactsContainer>
                <Contact
                    allowSelection
                    href={API_URL + '/vcard/' + card.slug}
                    target="_blank"
                >
                    Add to contacts
                </Contact>
            </OnlyAddToContactsContainer>
        )
    }

    return (
        <Container>
            {!demo && (
                <Contact
                    allowSelection
                    href={API_URL + '/vcard/' + card.slug}
                    target="_blank"
                >
                    Add to contacts
                </Contact>
            )}

            <ShareLink href={makeShareLink('whatsapp', '')}>
                <Whatsapp />
            </ShareLink>

            <ShareLink href={makeShareLink('sms', '')}>
                <Sms
                    onClick={() => {
                        // setType('sms') ||
                        //     setShareTitle('Share by SMS') ||
                        //     setInputType('tel') ||
                        //     setInputPlaceholder('Enter mobile number') ||
                        //     setOpen(true)
                    }}
                />
            </ShareLink>

            <ShareLink href={makeShareLink('email', '')}>
                <Envelope
                    onClick={() => {
                        // setType('email') ||
                        //     setShareTitle('Share by Email') ||
                        //     setInputType('email') ||
                        //     setInputPlaceholder('Enter email') ||
                        //     setOpen(true)
                    }}
                />
            </ShareLink>

            <MobilePicker
                isOpen={isOpen}
                title={shareTitle}
                onChange={e => setRecepient(e.target.value)}
                closeButton={<CloseButton onClick={() => setOpen(false)} />}
                shareLink={shareLink}
                inputType={inputType}
                inputPlaceHolder={inputPlaceHolder}
            />
        </Container>
    )
}

const Modal = styled.div`
    position: fixed;
    background-color: white;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 30;
    padding: 1rem;
`

const CloseButton = styled(IoIosCloseCircleOutline)`
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    display: block;
`

function MobilePicker({
    title = 'Share now',
    isOpen,
    onChange = () => {},
    closeButton,
    shareLink,
    inputType,
    inputPlaceHolder,
}) {
    const [value, setValue] = useState('')

    return isOpen ? (
        <Portal>
            <Modal>
                {closeButton}
                <h2>{title}</h2>
                <Input
                    label="Mobile number"
                    floating
                    placeholder={inputPlaceHolder}
                    type={inputType}
                    onChange={e => setValue(e.target.value) || onChange(e)}
                />
                <Button
                    href={shareLink}
                    target="_blank"
                    disabled={!value.length}
                >
                    Send now
                </Button>
            </Modal>
        </Portal>
    ) : null
}
