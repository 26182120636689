import React from 'react'

import Layout from '../components/layout'

import Card from '../components/card'

import Header from '../components/header'

import Footer from '../components/footer'

export default () => {
    return (
        <Layout>
            <Header mode="company-header" />
            <Card />
            <Footer />
        </Layout>
    )
}
