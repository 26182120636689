import React, { useEffect, useState } from 'react'

import SEO from './seo'

import styled, { keyframes } from 'styled-components'

import VerifiedImage from '../images/verified-badge.svg'

import { FaSpinner } from 'react-icons/fa'

import SocialShare from './social-share'

import { Link } from './styles'

import { useQuery } from '@apollo/react-hooks'

import { GET_BUSINESS_CARD_BY_SLUG } from './data-provider'

import { useBusinessCardSlug } from '../hooks'

import CroppedImage from './cropped-image'

import { get, isEmpty } from 'lodash-es'

import DynamicManifest from './dynamic-manifest'

import queryString from 'query-string'

const Card = styled.div`
    box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto 1rem;
    max-width: 95vw;
    @media (min-width: 480px) {
        max-width: 400px;
    }
    min-height: 200px;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    transition: ease 1s;

    @media (min-height: 700px) and (min-width: 1025px) {
        margin-top: 5%;
        margin-bottom: 5rem;
    }

    @media print {
        box-shadow: none;
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: none;

        width: 105mm;
        height: 148mm;
        border: 1px solid black;
        border-radius: 0;
    }
`

const Spam = styled.span`
    color: ${props => props.theme.red};
`

const Title = styled.h2`
    font-size: 1.5rem;
    line-height: 140%;
    text-align: center;
    font-weight: 500;
    max-width: 600px;
    padding: 1rem;
`

const Container = styled.div`
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Circle = styled.div`
    content: '';
    display: block;
    background: #f4f7f7;
    border-radius: 100%;
    position: absolute;
    right: -20px;
    bottom: -80px;
    z-index: -1;
    width: 260px;
    height: 260px;
    @media print {
        display: none;
    }
`

const CircleContainer = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
`

const Header = styled.header`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 0.5rem;
    align-content: stretch;
`

const H1 = styled.h1`
    font-weight: 700;
    margin: 0rem 0 0.25rem;
    font-size: 1.6rem;
`

const H3 = styled.h3`
    font-weight: 400;
    margin: 0;
    font-size: 1rem;
`

const Nav = styled.nav`
    margin: auto;
`

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    display: block;
    padding: 0;
`

const ListItem = styled.li`
    display: table-row;
`

const CompanyName = styled(ListItem)`
    display: none;
    margin-top: 3rem;
    @media print {
        display: block;
    }
`

const ListItemLink = styled.a`
    display: table-cell;
    text-decoration: none;
    color: ${props => props.theme.gray1};
    padding: 0.1rem 0 0.1rem 1rem;
    @media (min-height: 667px) {
        padding: 0.3rem 0 0.3rem 1rem;
    }
    font-size: ${props => (props.small ? '.8rem' : '1rem')};
    line-height: 140%;
`
const QrCodeImage = styled.img`
    width: 150px;
    height: 150px;
    display: block;
    padding: 1rem;
`

const Photo = styled(CroppedImage)`
    width: 120px;
    height: 120px;
    margin-bottom: 1rem;
    border: 1px solid #eee;
    margin-right: 1rem;
    border-radius: 50%;
`

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media print {
        justify-content: space-between;
        margin-bottom: 2rem;
    }
`

const ItemTitle = styled.strong`
    display: table-cell;
    min-width: 5.1rem;
`

const PhotoContainer = styled.div`
    position: relative;
`

const VerifiedContainer = styled.div`
    bottom: 18px;
    right: 10px;
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    padding: 2px;
`
const Verified = styled(VerifiedImage)`
    width: 100%;
    height: auto;
`

const BottomLinks = styled.div`
    margin-top: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    @media print {
        display: none;
    }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(FaSpinner)`
    font-size: 4rem;
    animation: ${spin} 1s linear infinite;
`

const VerifiedPopup = styled.div`
    position: absolute;
    background-color: white;
    left: -100px;
    right: -100px;
    padding: 0.5rem;
    font-size: 0.7rem;
    font-weight: 700;
    text-align: left;
    border: 1px solid ${props => props.theme.gray1};
    line-height: 140%;
    pointer-events: none;
    transition: 0.3s ease;
    opacity: ${props => (props.visible ? 1 : 0)};
`

const Event = styled.div`
    font-weight: 800;
`
const Credit = styled.div`
    display: none;
    font-size: 0.8rem;
    margin-top: 2rem;
    text-align: center;
    @media print {
        display: block;
    }
`

function getPhotoUrl(card) {
    return (
        (card.personalPhoto &&
            process.env.GATSBY_API_ROOT + card.personalPhoto.url) ||
        card.avatarUrl
    )
}

function getQrCodeUrl(card) {
    return (
        card &&
        card.slug &&
        `${process.env.GATSBY_API_ROOT}/qr-code/${card.slug}`
    )
}

function formatWebsite(website) {
    if (!website.match('http')) {
        return `http://${website}`
    }
    return website
}

export default () => {
    const [slug] = useBusinessCardSlug()

    const [cardNotFound, setCardNotFound] = useState(false)

    const [verifiedPopupVisible, setVerifiedPopupVisible] = useState(false)

    // eslint-disable-next-line
    const { data: remoteData } = useQuery(GET_BUSINESS_CARD_BY_SLUG, {
        variables: { slug },
    })

    const card = get(remoteData, 'businessCard')

    // TODO: Move the activation functionality on before validation
    if (card) card.active = true

    useEffect(() => {
        let handle
        if (card && !card.active) {
            if (typeof window !== 'undefined') {
                handle = setTimeout(function() {
                    window.location.reload()
                }, 15000)
            }
        }

        if (remoteData && card === null) {
            // card not found
            setCardNotFound(true)
        }

        if (remoteData && card) {
            setCardNotFound(false)
        }

        return () => {
            clearTimeout(handle)
        }
    }, [card, remoteData])

    useEffect(() => {
        let handle

        if (verifiedPopupVisible) {
            handle = setTimeout(() => setVerifiedPopupVisible(false), 2000)
        }

        return () => {
            clearTimeout(handle)
        }
    }, [verifiedPopupVisible])

    useEffect(() => {
        if (!card) return

        if (typeof window === 'undefined') return

        const parsed = queryString.parse(window.location.search, {
            parseBooleans: true,
        })

        if (parsed.print) {
            setTimeout(() => window.print(), 500)
        }
    }, [card])

    if (cardNotFound) {
        return (
            <Container>
                <SEO title={'Card not found'} />
                <Title>
                    We are sorry, this card doesn't exist yet! Go ahead and{' '}
                    <Link to="/create-business-card" inline={1}>
                        create your card right now
                    </Link>
                </Title>
            </Container>
        )
    }

    if (card && !card.active) {
        return (
            <Container>
                <SEO
                    title={
                        card.firstName +
                        ' ' +
                        card.lastName +
                        ' | ' +
                        'Inactive Card'
                    }
                />
                <Title>
                    Your business card is not active yet, check your inbox or
                    <Spam> spam</Spam> folder and follow the link
                </Title>
                <p>This page will auto refresh every 15 seconds</p>
            </Container>
        )
    }

    return card ? (
        <Card>
            <DynamicManifest
                name={card.firstName + ' ' + card.lastName}
                start_url={`/bc/${card.slug}`}
            />
            <SEO title={card.firstName + ' ' + card.lastName} />
            <Header>
                {card.eventName && <Event>{card.eventName}</Event>}
                <HeaderRow>
                    <PhotoContainer>
                        <Photo
                            src={getPhotoUrl(card)}
                            circle
                            crop={get(card, 'personalPhoto.crop')}
                        />
                        {card.verified && (
                            <VerifiedContainer>
                                <Verified
                                    onTouchStart={() =>
                                        setVerifiedPopupVisible(true)
                                    }
                                    onMouseOver={() => {
                                        setVerifiedPopupVisible(true)
                                    }}
                                >
                                    VERIFIED
                                </Verified>
                                <VerifiedPopup
                                    visible={verifiedPopupVisible ? 1 : 0}
                                >
                                    This business card has been verified by
                                    VERIFECO. The golden verification sign is
                                    given in accordance with the VERIFECO terms
                                    of verification.
                                </VerifiedPopup>
                            </VerifiedContainer>
                        )}
                    </PhotoContainer>
                    <QrCodeImage src={getQrCodeUrl(card)} alt="QR Code" />
                </HeaderRow>

                <div>
                    <H1>{card.firstName + ' ' + card.lastName}</H1>
                    <H3>{card.title}</H3>
                </div>
            </Header>
            <Nav>
                <List>
                    <CompanyName>
                        <ItemTitle>Company</ItemTitle>
                        <ListItemLink>{card.organizationName}</ListItemLink>
                    </CompanyName>
                    <ListItem>
                        <ItemTitle>email</ItemTitle>
                        <ListItemLink href={'mailto:' + card.email}>
                            {card.email}
                        </ListItemLink>
                    </ListItem>
                    <ListItem>
                        <ItemTitle>mobile</ItemTitle>
                        <ListItemLink href={`tel:${card.workCell}`}>
                            {card.workCell}
                        </ListItemLink>
                    </ListItem>
                    <ListItem>
                        <ItemTitle>phone</ItemTitle>
                        <ListItemLink href={`tel:${card.workPhone}`}>
                            {card.workPhone}
                        </ListItemLink>
                    </ListItem>
                    <ListItem>
                        <ItemTitle>address</ItemTitle>
                        <ListItemLink small>{card.address}</ListItemLink>
                    </ListItem>
                    {!isEmpty(card.licenseNumber) && (
                        <ListItem>
                            <ItemTitle>license no.</ItemTitle>
                            <ListItemLink>{card.licenseNumber}</ListItemLink>
                        </ListItem>
                    )}
                    {!isEmpty(card.website) && (
                        <ListItem>
                            <ItemTitle>website</ItemTitle>
                            <ListItemLink
                                target="_blank"
                                href={formatWebsite(card.website)}
                            >
                                {card.website}
                            </ListItemLink>
                        </ListItem>
                    )}
                </List>
            </Nav>
            <CircleContainer>
                <Circle />
            </CircleContainer>

            <SocialShare card={card} />
            <BottomLinks>
                <Link to={'/create-business-card'}>
                    Create your business card (free)
                </Link>
            </BottomLinks>
            <Credit>Created with Verifeco.com</Credit>
        </Card>
    ) : (
        <Container>
            <Spinner />
        </Container>
    )
}
